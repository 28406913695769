import { Link } from "gatsby"
import * as React from "react"
import './styles.scss'
import 'fontsource-fira-sans'
import ProfPic from "../images/r2d2.jpg"

const Header = () => {
    return (
        <main>
                <div className="firstLinkPadding">
                    <Link className="linkColor" to="/home">Home</Link>
                    <Link className="linkColor otherLinks" to="/resume">Resume</Link>
                    <Link className="linkColor otherLinks" to="/projects">Projects</Link>
                </div>
                <div className="headerBox d-flex flex-row customFlex">
                    <div className="profileWidth ml-3 mb-3 mt-3">
                        <img className="profileImage" src={ProfPic} alt="" />
                    </div>
                    <div className="d-inline-flex flex-column mt-2">
                        <div className="setTopFirst">
                            <h3>Prashanth Keth</h3>
                        </div>
                        <div className="setTop">
                            <h6>Software Engineer</h6>
                        </div>
                        <div className="icons setTop">
                            <a href='https://github.com/prashketh'><i className="fab fa-github fa-2x"></i></a>
                            <a className="ml-4" href='https://www.linkedin.com/in/prashketh/'><i className="fab fa-linkedin fa-2x"></i></a>
                            <a className="ml-4" href="https://discordapp.com/users/Yonder#6139"><i
                                className="fab fa-discord fa-2x"></i></a>
                            <a className="ml-4" href="mailto: prashanthketheeswaran@gmail.com"><i
                                className="fa fa-at fa-2x"></i></a>
                        </div>
                    </div>
                </div>
        </main>
    )
}

export default Header
