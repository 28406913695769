import * as React from 'react'
import './styles.scss'
import 'fontsource-fira-sans'
import ProfPic from '../images/r2d2.jpg'
import JavaScriptPic from '../images/javascript.svg'
import JavaPic from '../images/java.svg'
import PythonPic from '../images/python.svg'
import ReactPic from '../images/react.svg'
import AngularPic from '../images/angular.svg'
import NodePic from '../images/nodejs-icon.svg'
import MongoPic from '../images/mongodb.png'
import SassPic from '../images/sass-1.svg'
import FirebasePic from '../images/firebase.svg'
import Header from './header'
import Footer from './footer'
import 'bootstrap/dist/css/bootstrap.css'

const HomePage = () => {
	return (
		<main>
			<head>
				<title>Home</title>
			</head>
			<meta
				name='viewport'
				content='width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0'
			/>
			<link
				rel='stylesheet'
				href='https://use.fontawesome.com/releases/v5.7.2/css/all.css'
				integrity='sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr'
				crossorigin='anonymous'
			/>
			<div className='boxWidth'>
				<Header />
				<div className='userText resumeContainer noBox'>
					<h4 className='resumeTitle mt-3'>About</h4>
					<div>
						<div className='mt-3'>
							<p>Welcome, I'm Prashanth!</p>
							<p>
								I'm a graduate from the University of Toronto where I majored in
								Computer Science. I took my first leap into the field when I was
								in high school and have ever since continued to delve deeper
								into both the theoretical and practical applications of Computer
								Science. Currently I am specializing in Software Engineering,
								where a greater emphasis is placed on the engineering side of
								the discipline, which includes computer systems and core
								applications.
							</p>
							<p>
								Over the course of my education, I have been lucky enough to
								have learned new skills and experienced new things through
								internships. Some of the places where I have interned at are
								Meta (formerly Facebook), Amazon and Appnovation. I'm always looking for
								new and exciting opportunities!
							</p>
							<p>
								Thanks for visiting my site! Feel free to check out some of the
								projects I've been working on, or get in touch through any
								preferred channels!
							</p>
							<p className='textCenter'>
								Below are some of the tech that I have used:{' '}
							</p>
							<div className='logoContainer'>
								<img alt='JavaScript' src={JavaScriptPic} />
								<img alt='Java' src={JavaPic} />
								<img alt='Python' src={PythonPic} />
								<img alt='React' src={ReactPic} />
								<img alt='Angular' src={AngularPic} />
								<img alt='NodeJs' src={NodePic} />
								<img alt='Mongo' src={MongoPic} />
								<img alt='Sass' src={SassPic} />
								<img alt='Firebase' src={FirebasePic} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</main>
	)
}

export default HomePage
